<template>
    <div class="page">
        <!-- 引入的返回头组件 -->
        <!-- <titlebar :name="name"></titlebar> -->
        <div class="box">
            <div class="left_box">
                <!-- 左上方搜索栏 -->
                <div class="search">
                    <van-icon name="search" size="28" class="search_icon" />
                    <input type="number" placeholder="输入订单号" v-model="order_num" />
                </div>
                <!-- 左侧选择日期区间 -->
                <!-- <div class="chose_time">
                    <div class="dateBirth" @click="opendate">
                        <span v-if="!startDate">起始日期</span><span class="color000">{{ startDate }}</span>
                        <van-icon name="notes-o" class="gang" />
                    </div>
                    <div class="gang">一</div>
                    <div class="dateBirth" @click="opendate">
                        <span v-if="!startDate">结束日期 </span>
                        <span class="color000">{{ endDate }}</span>
                        <van-icon name="notes-o" class="gang" />
                    </div>
                </div> -->
                <!-- 左侧选择今天,近七天,近一个月 -->
                <div class="date_btn_box">
                    <van-button class="date_btn" @click="changeTtype(1)" :class="{ liBackground:t_type == 1}">今天</van-button>
                    <van-button class="date_btn" @click="changeTtype(2)" :class="{ liBackground:t_type == 2}">近七天</van-button>
                    <van-button class="date_btn" @click="changeTtype(3)" :class="{ liBackground:t_type == 3}">近一个月</van-button>
                    <van-icon name="notes-o" class="mg-lt15" size="3.5rem" color="#000" @click="show = true" />
                </div>
                <!-- 确认筛选按钮 -->
                <div>
                    <van-button class="reset_btn" @click="restDate">重置</van-button>
                    <van-button class="screen_btn" @click="handleFifler">筛选</van-button>
                </div>
                <!-- 订单号列表 -->
                <div class="num_box">
                    <div class="num_list" v-for="(item, i) in orderList" :key="i" :class="{ choose: chooseIndex == i }" @click="chooseOrder(item, i)">
                        <div><div v-if="item.is_order_return == 1" class="return-tag">退</div><span class="va-middle">订单号</span></div>
                        <div class="list_num">
                            <div class="num_left">{{ item.order_num }}</div>
                            <div class="num-rigth">¥{{ item.total_price }}</div>
                        </div>
                    </div>
                </div>
                <div style="width: 100%; margin: 0 auto; height: 9rem;" class="flex flex-ac nowrap">
                    <van-pagination style="width: 100%;" v-model="page" :total-items="total" :items-per-page="limit" @change="pageChange" force-ellipses mode="simple" />
                </div>
            </div>
            <div class="right_box" v-if="orderList.length > 0">
                <div class="box_title">
                    <div class="box_name">商品名称</div>
                    <div>数量</div>
                    <div>单价（元）</div>
                    <div>小计（元）</div>
                </div>
                <!-- 可滚动区域 -->
                <div class="scrollbar">
                    <!-- 商品列表 -->
                    <div class="box_index" v-for="(item, i) in orderInfo.info" :key="i">
                        <div class="box_name">{{ item.name }}</div>
                        <div>{{ Number(item.num) }}({{item.dw}})<span class="color-e60012 mg-lt15" v-if="item.return_count && item.return_count > 0">退{{ item.return_count }}</span></div>
                        <div><span class="va-middle">{{ item.price }}</span><div v-if="item.is_editprice == 1" class="change-tag">改</div></div>
                        <div>{{ $bigN(item.num).times(item.price).toFixed(2) }}</div>
                    </div>
                    <!-- 订单信息 -->
                    <div class="orderinfo">
                        <div class="orderinfo_logo">订单信息</div>
                        <div class="orderinfo_index">
                            <div class="index_text">
                                <div class="t_name">订单编号</div>
                                <div>{{ orderInfo.order_num }}</div>
                            </div>
                            <div class="index_text">
                                <div class="t_name">收银时间</div>
                                <div>{{ orderInfo.pay_time }}</div>
                            </div>
                            <div class="index_text">
                                <div class="t_name">操作人员</div>
                                <div>{{ orderInfo.staff_name }}</div>
                            </div>
                            <div class="index_text" v-if="this.orderInfo.member && Object.keys(this.orderInfo.member).length">
                                <div class="t_name">会员信息</div>
                                <div>{{ orderInfo.member.name }}&nbsp;&nbsp;&nbsp;{{ orderInfo.member.phone }}</div>
                            </div>
                        </div>
                    </div>
                    <!-- 订单金额,支付信息 -->
                    <div class="orderinfo orderinfo-price-box">
                        <div class="orderinfo_logo">订单金额</div>
                        <div class="orderinfo_index">
                            <div class="index_text">原价：¥{{ orderInfo.change_price }}</div>
                            <div class="index_text flex-ac" v-if="total_discount_price > 0">
                                优惠：-¥{{ total_discount_price }}
                                <van-icon name="info" color="#1588F5" size="3rem" class="mg-lt10" @click="openDiscountDetail" />
                            </div>
                            <div class="index_text">应收：¥{{ orderInfo.total_price }}</div>
                            <div class="index_text">实收：¥{{ actual_amount }}（{{ orderInfo.pay_type | PayType }}）</div>
                            <div class="index_text" v-if="orderInfo.return_money && orderInfo.return_money > 0">
                                <div>退款：-¥{{ orderInfo.return_money }}</div>
                                <div class="mg-lt130" v-if="orderInfo.return_score && orderInfo.return_score > 0">退积分：-{{ orderInfo.return_score }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btm_btn_box">
                <div class="btm_btn_box-btn">
                </div>
                <div>
                    <van-button class="btm_btn" @click="sales()">退货</van-button>
                    <van-button class="btm_btn" @click="print">打印</van-button>
                </div>
            </div>
        </div>
        <!-- 日历弹窗 -->
        <!-- <van-calendar v-model="show" :show-confirm="false" type="range" @confirm="onConfirm" color="#1588F5 " :allow-same-day="true" ref="cleardate" :minDate="minDate" :max-date="maxdate" /> -->
        
        <!-- 完结订单时间筛选 -->
        <van-popup v-model="show" round position="bottom" class="popup-picker-custom">
            <van-datetime-picker
                v-model="defaultDate"
                :min-date="minDate"
                :max-date="maxdate"
                type="date"
                @confirm="onConfirm"
                @cancel="show = false"
                item-height="8.5rem"
                :formatter="formatter"
            />
        </van-popup>

        <popupOfDialog
            v-if="showDialog"
            :type="dialogType"
            :title="dialogTitle"
            :message="dialogMsg"
            :data="dialogData"
            @closePopDialog="closePopDialog"
            @confirmPopDialog="confirmPopDialog"
            :showCancelBtn="false"
            :showConfirmBtn="false"
        ></popupOfDialog>
    </div>
</template>

<script>
import titlebar from "@/views/component/titlebar";
import { deviceInfo } from '../../utils/outPrint';
import { checkPrintType, datetimePickerFormatter } from '../../utils/util';
import popupOfDialog from "../component/popupOfDialog.vue";
export default {
    async created() {
        this.name = this.$route.query.name || ""; //拿到传过来的标题
        console.log("name:", this.name);
        this.getList();
        const newD = new Date().setMonth(new Date().getMonth() - 2);
        this.minDate = new Date(newD);
        this.maxdate = new Date();
        if (this.isInApp == 'android' || this.isInApp == 'mt_android') {
            deviceInfo();
        } else if (this.isInApp == 'windows') {
            await this.$device.getLocalIP();
        }
        checkPrintType();
    },
    components: {
        titlebar: titlebar,
        popupOfDialog,
    },
    filters: {
        PayType(val) {
            let obj = {
                1: "微信",
                2: '支付宝',
                3: "现金",
                4: '云闪付',
                6: '会员余额'
            };
            return obj[val] || "";
        },
    },
    data() {
        return {
            date: "",
            show: false, //显示选择日期
            minDate: new Date(2021, 6, 1), //设置起始时间时间为2021年
            maxdate: new Date(), //最大日期是当天
            startDate: "", //开始时间
            endDate: "", //结束时间
            chooseIndex: 0, //选中的订单下标
            t_type: 0,
            order_num: undefined, // 订单编号
            page: 1,
            limit: 10,
            orderList: [],
            total: 0,
            orderInfo: {
                info: [],
            }, // 订单详情
            curOrderNum: "", // 当前选择的订单编号
            formatter: datetimePickerFormatter,
            defaultDate: new Date(),
            showDialog: false,
            dialogTitle: "",
            dialogMsg: "",
            dialogType: "",
            dialogData: {},
        };
    },
    computed: {
        isInApp() {
            return localStorage.nowDevice || '';
        },
        actual_amount() {
            return this.$bigN(this.orderInfo.total_price).minus(this.orderInfo.return_money).toFixed(2);
        },
        total_discount_price() {
            let price = 0;
            price = 
                this.$bigN(this.orderInfo.discount_amount)
                .plus(this.orderInfo.score_discount)
                .plus(this.orderInfo.discount_rate_price || 0)
                .plus(this.orderInfo.wipe_price || 0)
                .plus(this.orderInfo.coupon_price || 0)
                .toFixed(2);
            return price;
        },
    },
    methods: {
        openDiscountDetail() {
            this.dialogTitle = "优惠明细";
            this.dialogType = '3';
            let dis_data = [];
            if (this.orderInfo.discount_amount && this.orderInfo.discount_amount > 0) {
                let meme_d_name = '会员优惠';
                if (this.orderInfo.is_member_price == 1) {
                    meme_d_name = '会员价优惠';
                } else if (this.orderInfo.is_member_price != 1) {
                    meme_d_name = `会员折扣:${ this.orderInfo.member_type.rate / 10 }折`
                }
                dis_data.push({ name: meme_d_name, value: this.orderInfo.discount_amount })
            }
            if (this.orderInfo.discount_rate_price && this.orderInfo.discount_rate_price > 0) {
                dis_data.push({ name: `打折金额:${ this.$bigN(this.orderInfo.discount_rate).times(10) }折`, value: this.orderInfo.discount_rate_price })
            }
            if (this.orderInfo.wipe_price && this.orderInfo.wipe_price > 0) {
                dis_data.push({ name: '抹零金额', value: this.orderInfo.wipe_price })
            }
            if (this.orderInfo.score_discount && this.orderInfo.score_discount > 0) {
                dis_data.push({ name: '积分抵扣', value: this.orderInfo.score_discount })
            }
            if (this.orderInfo.coupon_price && this.orderInfo.coupon_price > 0) {
                dis_data.push({ name: `优惠券:${this.orderInfo.coupon_name}`, value: this.orderInfo.coupon_price })
            }
            this.dialogData = {
                'dis': dis_data
            }
            this.showDialog = true;
        },
        confirmPopDialog() {
            this.closePopDialog();
        },
        closePopDialog() {
            this.showDialog = false;
        },
        getPayType() {
            let obj = {
                1: "微信",
                2: '支付宝',
                3: "现金",
                4: '云闪付',
                6: '会员余额'
            };
            return obj[this.orderInfo.pay_type] || "";
        },
        // 跳转至订单退货
        goToReturnTheOrder() {
            this.$router.push({
                path: "/returnTheOrder",
                query: { name: "returnTheOrder" },
            });
        },
        // 跳转至退货页面
        sales() {
            this.$router.push({
                path: "/purchase",
                query: { name: "purchase", curOrderNum: this.curOrderNum },
            });
        },
        // 页码改变
        pageChange(e) {
            this.page = e;
            this.chooseIndex = 0;
            console.log("销售商品列表，当前页码：", this.page);
            this.getList();
        },
        handleFifler() {
            this.page = 1;
            // 如果有输入订单，清空当前选择订单编号
            if (this.order_num != "") {
                this.curOrderNum = undefined;
            }

            this.getList();
        },
        changeTtype(type) {
            this.t_type = type;
            // 如果选择了近几天，清空日期选择
            if (this.t_type != 0) {
                this.startDate = "";
                this.endDate = "";
            }
        },
        getList() {
            let query = {
                shop_id: localStorage.getItem("shop_id"),
                o_type: 1, // 1.已销售订单 2.已售后订单
                s_time: this.startDate,
                e_time: this.endDate,
                t_type: this.t_type,
                order_num: this.order_num,
                page: this.page,
                limit: this.limit,
            };
            this.$api.orderList(query).then((res) => {
                this.orderList = res.data.list || [];
                this.curOrderNum =
                    res.data.list.length > 0 ? res.data.list[0].order_num : "";
                this.total = res.data.count;
                console.log("订单总数：", this.total);
                if (res.data.list.length > 0) {
                    this.getDetail();
                }
            });
        },
        // 获取详情
        getDetail() {
            this.$api
                .orderInfo({
                    shop_id: localStorage.getItem("shop_id"),
                    o_type: 1, // 1.已销售订单 2.已售后订单
                    order_num: this.curOrderNum,
                })
                .then((res) => {
                    if (res.code == 1) {
                        this.orderInfo = res.data;
                        console.log("订单详情：", this.orderInfo);
                    }
                });
        },
        // 重置
        restDate() {
            this.order_num = undefined;

            this.startDate = "";
            this.endDate = "";
            this.t_type = 0;
            this.getList();
        },
        // 选择订单
        chooseOrder(item, i) {
            this.chooseIndex = i;
            this.curOrderNum = item.order_num;
            this.getDetail();
        },
        // 获取日期
        formatDate(date) {
            console.log(date);
            return `${date.getFullYear()}/${
                date.getMonth() + 1
            }/${date.getDate()}`;
        },
        // 赋值日期
        onConfirm(date) {
            console.log(date);
            // const [start, end] = date;
            this.show = false;
            // this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
            this.startDate = `${this.formatDate(date)}`;
            this.endDate = `${this.formatDate(date)}`;
            console.log(this.startDate, this.endDate);
            // 如果选择了时间，清空近几天
            if (this.startDate && this.endDate) {
                this.t_type = 0;
            }
            this.handleFifler();
        },
        // 打开选择日期框
        opendate() {
            // 重置时间
            this.$refs.cleardate.reset();
            this.show = true;
        },
        print () {
            if (this.orderInfo.print_receipt == 1) {
                let printData = Object.assign({}, JSON.parse(this.orderInfo.print_data), this.orderInfo);
                // printData['pay_time'] = this.orderInfo.pay_time || '';
                // printData['discount_amount'] = this.orderInfo.discount_amount || '0';
                // printData['is_member_price'] = this.orderInfo.is_member_price;
                // printData['member'] = this.orderInfo.member || {};
                // printData['member_type'] = this.orderInfo.member_type || {};
                // printData['pay_type'] = this.orderInfo.pay_type || {};
                printData['isReprint'] = 1;
                printData['pay_type_text'] = this.getPayType();
                printData['actual_amount'] = this.actual_amount || 0;
                printData['discount_rate_text'] = this.orderInfo.discount_rate ? this.$bigN(this.orderInfo.discount_rate).times(10).toString() : '';
                this.$printFunc.printReceipt(printData, this.orderInfo.id);
            }
            // if (this.orderInfo.print_data && typeof KissSunmiPrint !== 'undefined') {
            //     let printData = JSON.parse(this.orderInfo.print_data);
            //     printData.cp = '1';
            //     //开启打印
            //     KissSunmiPrint.transactionPrint([printData]).then(function(msg) {
            //         console.log("============================3" + msg)
            //     })
            //     .catch(function(error){
            //         console.log("============================4" + error)
            //     });
            // }
            // 播报语音
            if (this.orderInfo.audio_url) {
                const instance = new Audio(); // 创建音频对象
                instance.src = res.data.list.audio_url; // 设置音频播放地址
                instance.play(); // 调用音频播放
            }
        }
    },
    mounted() {},
};
</script>

<style lang="less" scoped>
.abnormal {
    width: 16.8rem;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.1rem solid #e5e5e5;
    font-size: 2.6rem;
    font-weight: 400;
    color: #1588F5;
    background: rgb(245, 242, 242);
    margin-right: auto;
    margin-left: 4rem;
    border-radius: 0.6rem;
}

// /deep/.van-pagination__item--active {
//     color: #fff !important;
//     background-color: #1588F5 !important;
// }
// /deep/.van-pagination__item {
//     color: #1588F5;
// }

.btm_btn_box {
    position: fixed;
    bottom: 0;
    right: 1.5rem;
    width: calc(100vw - 64rem);
    height: 9rem;
    background: #ffffff;
    border: 0.1rem solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btm_btn {
        width: 19.4rem;
        height: 6rem;
        background: #1588F5;
        font-size: 2.6rem;
        font-weight: 400;
        color: #ffffff;
        margin-right: 4rem;
        border-radius: 0.6rem;
    }
    .return_btn {
        background: #fff;
        border: 0.1rem solid #e5e5e5;
        color: #333;
    }

    .btm_btn_box-btn {
        flex: 1;
        display: flex;
        .abnormal {
            margin-right: 0;
        }
    }
}
.color000 {
    color: #000;
}
.color1ccfcf {
    color: #1588F5;
}
.page {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .box {
        display: flex;
        width: 100%;
        // height: 98rem;
        background-color: #f8f8f8;
        .left_box {
            width: 47.5rem;
            // height: 98rem;
            height: calc(100vh - 10.3rem);
            background-color: #fff;
            display: flex;
            flex-direction: column;
            .num_box {
                // height: 54rem;
                flex: 1;
                overflow-y: scroll;
                font-size: 2.2rem;
                font-weight: 400;
                color: #333333;
                text-align: left;
                .num_list {
                    padding: 1.5rem 3rem;
                    border: 0.1rem solid #e5e5e5;
                    .list_num {
                        display: flex;
                        justify-content: space-between;
                        padding-top: 1rem;
                        max-width: 51rem;
                        .num_left {
                            max-width: calc(100% - 7.7rem);
                            word-break: break-all;
                        }
                    }

                    .return-tag {
                        background: #e60012;
                        width: 3rem;
                        height: 3rem;
                        border-radius: 0.8rem;
                        text-align: center;
                        line-height: 2.9rem;
                        color: #fff;
                        display: inline-block;
                        font-size: 1.8rem;
                        margin-right: 0.5rem;
                    }
                }
                .choose {
                    background: rgba(254, 93, 0, 0.06);
                    color: #1588F5;
                }
            }
            .num_box::-webkit-scrollbar {
                display: none;
            }
            .search {
                display: flex;
                height: 8rem;
                justify-content: center;
                align-items: center;
                font-size: 2.4rem;
                font-weight: 400;
                color: #999999;
                border-bottom: 0.1rem solid #e5e5e5;
                margin-bottom: 2.1rem;
                .search_icon {
                    margin-right: 3rem;
                }
            }
            .chose_time {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 2rem;
                font-weight: 400;
                color: #999999;
                margin-bottom: 2.4rem;
                .dateBirth {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 17.7rem;
                    height: 5.7rem;
                    background: #ffffff;
                    border-radius: 0.8rem;
                    border: 0.1rem solid #999999;
                }
                .gang {
                    margin: 0 1rem;
                }
            }
            .date_btn_box {
                margin-bottom: 3rem;
                display: flex;
                align-items: center;
                justify-content: center;
                .date_btn {
                    height: 4rem;
                    background: #f4f4f4;
                    font-size: 1.6rem;
                    margin-right: 1.5rem;
                }
                .liBackground {
                    background: #1588F5;
                    color: #ffffff;
                }
            }
            .screen_btn {
                width: 18rem;
                height: 5rem;
                background: #1588F5;
                color: #fff;
                font-size: 2.4rem;
                margin-bottom: 2.3rem;
            }
            .reset_btn {
                width: 15rem;
                height: 5rem;
                color: #333;
                border: 0.1rem solid #e5e5e5;
                font-size: 2.4rem;
                margin-bottom: 2.3rem;
                margin-right: 1rem;
            }
        }
        .right_box {
            width: calc(100% - 47.5rem);
            // height: 98rem;
            .box_title {
                height: 6rem;
                border-bottom: 0.1rem solid #e5e5e5;
                display: grid;
                grid-template-columns: repeat(4, 25%);
                align-items: center;
                font-size: 2.4rem;
                font-weight: 400;
                color: #333333;
                padding: 0 5rem;
                margin: 0 1.5rem;
                background: #f2f2f2;
            }
            .box_index {
                border-bottom: 0.1rem solid #e5e5e5;
                display: grid;
                grid-template-columns: repeat(4, 25%);
                align-items: center;
                font-size: 2.4rem;
                font-weight: 400;
                color: #333333;
                padding: 2rem 5rem;
                background-color: #fff;
                margin: 0 1.5rem;
                .change-tag {
                    background: #1588f5;
                    width: 2.8rem;
                    height: 2.8rem;
                    text-align: center;
                    line-height: 2.8rem;
                    border-radius: 0.8rem;
                    color: #fff;
                    display: inline-block;
                    font-size: 1.8rem;
                    margin-left: 1rem;
                }
            }
            .box_name {
                text-align: left;
            }
            .pd_lt60 {
                padding-left: 6rem;
            }
            .scrollbar {
                height: calc(100vh - 29.7rem);
                overflow-y: scroll;
            }
            .orderinfo {
                // width: 98%;
                height: 20.7rem;
                margin: 0 1.5rem;
                background: #ffffff;
                border: 0.1rem solid #e5e5e5;
                background-color: #fff;
                display: flex;
                align-items: center;
                margin-top: 2rem;
                margin-bottom: 2rem;
                .orderinfo_logo {
                    width: 18.5rem;
                    height: 20.7rem;
                    border-right: 0.1rem solid #e5e5e5;
                    font-size: 2.6rem;
                    font-weight: bold;
                    color: #333333;
                    line-height: 20.7rem;
                }
                .orderinfo_index {
                    text-align: left;
                    font-size: 2.4rem;
                    font-weight: 500;
                    color: #333333;
                    .index_text {
                        display: flex;
                        margin-left: 5rem;
                        margin-bottom: 1.5rem;
                        .t_name {
                            width: 15rem;
                        }
                    }
                }
            }

            .orderinfo-price-box {
                min-height: 20.7rem;
                height: fit-content;
                .orderinfo_index {
                    padding: 2rem 0;

                    .index_text {
                        margin-bottom: 2rem;
                    }
                }
            }
            .order_info {
                width: 98%;
                height: 9.6rem;
                margin: 0 auto;
                background: #ffffff;
                border: 0.1rem solid #e5e5e5;
                background-color: #fff;
                display: flex;
                align-items: center;
                .order_info_logo {
                    width: 18.5rem;
                    height: 9.6rem;
                    border-right: 0.1rem solid #e5e5e5;
                    font-size: 2.6rem;
                    font-weight: bold;
                    color: #333333;
                    line-height: 9.6rem;
                }
                .order_info_index {
                    text-align: left;
                    font-size: 2.4rem;
                    font-weight: 500;
                    color: #333333;
                    height: 9.6rem;
                    line-height: 9.6rem;
                    .index_text {
                        display: flex;
                        margin-left: 5rem;
                        margin-bottom: 1.5rem;
                        .t_name {
                            width: 21rem;
                        }
                    }
                }
            }
        }
    }
}
</style>
